import fetchWithRetry from './fetchWithRetry';
import type RequestOptions from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import { getConfig, type ServiceRequestConfig } from './config';
import checkAndLogMailboxInfo from './checkAndLogMailboxInfo';
import { getApp } from 'owa-config';
import { debugErrorThatWillShowErrorPopupOnly, trace } from 'owa-trace';
import getUrlWithAddedQueryParameters from 'owa-url/lib/getUrlWithAddedQueryParameters';
import { checkMailboxInfoForDiagnosticData } from './utils/checkMailboxInfoForDiagnosticData';
import { isServiceRequestSupportedForMailbox } from './utils/isServiceRequestSupportedForMailbox';
import { disallowedLicensingCallCheck } from './utils/disallowedLicensingCallCheck';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import { getOwaNetCoreEndpoint } from 'owa-netcore-flight/lib/getOwaNetCoreEndpoint';
import { isOwaNetCoreApiEnabled } from 'owa-netcore-flight/lib/isOwaNetCoreApiEnabled';
import { shouldCompareNetCoreResponse } from 'owa-netcore-flight/lib/shouldCompareNetCoreResponse';
import { shouldShadowCallNetCore } from 'owa-netcore-flight/lib/shouldShadowCallNetCore';
import { owaNetCoreComparator } from 'owa-netcore-flight/lib/owaNetCoreComparator';
import { addNetCoreAuthHeader } from 'owa-netcore-flight/lib/addNetCoreAuthHeader';
import { shouldNetCoreFallback } from 'owa-netcore-flight/lib/shouldNetCoreFallback';
import { logStartUsage } from 'owa-analytics-start';

export function makeServiceRequest<T>(
    actionName: string,
    parameters: any,
    options?: RequestOptions | MailboxRequestOptions
): Promise<T> {
    trace.info(`ServiceAction ${actionName}`, 'service');

    checkMailboxInfoForDiagnosticData(options?.mailboxInfo);
    if (!isServiceRequestSupportedForMailbox(options?.mailboxInfo)) {
        const msg = 'Cannot issue OWS request for a mailbox that does not support service requests';
        debugErrorThatWillShowErrorPopupOnly(msg);
        throw new Error(msg);
    }

    // Check to make sure this is not a disallowed licensing call
    disallowedLicensingCallCheck(options?.mailboxInfo, actionName);

    if (options?.mailboxInfo?.isRemoved) {
        debugErrorThatWillShowErrorPopupOnly('InvalidRequest: MailboxInfo was removed');
        throw new Error('InvalidRequest: MailboxInfo was removed');
    }

    const config = getConfig();
    checkAndLogMailboxInfo(config, 'Acct-MakeServiceRequestMailbox', options?.mailboxInfo);

    let endpoint;
    if (options?.endpoint) {
        endpoint = options.endpoint;
    } else {
        const baseUrl = options?.customBaseUrl
            ? options.customBaseUrlSubPath
                ? options.customBaseUrl.concat(options.customBaseUrlSubPath)
                : options.customBaseUrl
            : options?.customBaseUrlSubPath
            ? config.baseUrl.concat('/' + options.customBaseUrlSubPath)
            : config.baseUrl;
        endpoint = `${baseUrl}/service.svc?action=${actionName}`;
    }

    if (
        (config.isUserIdle !== undefined && config.isUserIdle()) ||
        options?.isUserActivity == false
    ) {
        endpoint = getUrlWithAddedQueryParameters(endpoint, { UA: '0' });
    }

    endpoint = getUrlWithAddedQueryParameters(
        endpoint,
        { app: getApp() },
        true /* ignore if present */
    );

    if (isOwaNetCoreApiEnabled(actionName, config.getApplicationSettings, options?.mailboxInfo)) {
        if (Object.keys(owaNetCoreComparator).includes(actionName)) {
            if (shouldCompareNetCoreResponse(config.getApplicationSettings, options?.mailboxInfo)) {
                return fetchComparison(
                    actionName,
                    endpoint,
                    config,
                    options,
                    parameters,
                    owaNetCoreComparator[actionName]
                );
            } else if (
                shouldShadowCallNetCore(config.getApplicationSettings, options?.mailboxInfo)
            ) {
                fetchNetCore(
                    actionName,
                    endpoint,
                    config,
                    options,
                    parameters,
                    false /* fallback */
                ).catch(() => {
                    logStartUsage('NetCoreShadowFailed', { actionName });
                });
                return fetchWithRetry(actionName, endpoint, 1, options, parameters);
            }
        }
        return fetchNetCore(
            actionName,
            endpoint,
            config,
            options,
            parameters,
            shouldNetCoreFallback(config.getApplicationSettings, options?.mailboxInfo)
        );
    }
    return fetchWithRetry(actionName, endpoint, 1, options, parameters);
}

function fetchNetCore(
    actionName: string,
    endpoint: string,
    config: ServiceRequestConfig,
    options?: RequestOptions | MailboxRequestOptions,
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    parameters?: any,
    fallback?: boolean
) {
    const netcoreEndpoint = getOwaNetCoreEndpoint(endpoint);
    const wantJsonResponse =
        !options?.returnResponseHeaders && !options?.returnFullResponseOnSuccess;
    const netcoreOptions: RequestOptions | MailboxRequestOptions = {
        ...options,
        returnResponseHeaders: true,
    };
    netcoreOptions.headers ??= new Headers();
    addNetCoreAuthHeader(
        netcoreOptions.headers as Headers,
        config.getApplicationSettings,
        netcoreOptions.mailboxInfo
    );
    // We know this is a owanetcore request so add x-responseorigin OwaNetCore in case we get a 404 or for whatever reason x-responseorigin isn't sent in the service response header.
    netcoreOptions.datapoint ??= {};
    netcoreOptions.datapoint.customData ??= {};
    netcoreOptions.datapoint.customData.responseOrigin = 'OwaNetCore';
    return fetchWithRetry(actionName, netcoreEndpoint, 1, netcoreOptions, parameters)
        .then(response => {
            if (
                fallback &&
                typeof response.status === 'number' &&
                !isSuccessStatusCode(response.status)
            ) {
                throw new Error('owanetcore response did not succeed');
            }
            if (wantJsonResponse) {
                return response.json();
            }
            return Promise.resolve(response);
        })
        .then(response => response)
        .catch(error => {
            if (fallback) {
                logStartUsage('NetCoreFallback', { actionName });
                const fallbackOptions: RequestOptions | MailboxRequestOptions = { ...options };
                fallbackOptions.datapoint ??= {};
                fallbackOptions.datapoint.customData ??= {};
                fallbackOptions.datapoint.customData.OwaNetCoreFallback = true;
                return fetchWithRetry(actionName, endpoint, 1, fallbackOptions, parameters);
            }
            throw error;
        });
}

function fetchComparison(
    actionName: string,
    endpoint: string,
    config: ServiceRequestConfig,
    options?: RequestOptions | MailboxRequestOptions,
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    parameters?: any,
    compareCallback?: (owaResponse: any, netcoreResponse: any, actionName: string) => any
) {
    const owaRequestPromise = fetchWithRetry(actionName, endpoint, 1, options, parameters);
    const netcoreRequestPromise = fetchNetCore(
        actionName,
        endpoint,
        config,
        options,
        parameters,
        false /* fallback */
    );
    return Promise.allSettled([owaRequestPromise, netcoreRequestPromise]).then(results => {
        const [owaResult, netcoreResult] = results;
        if (owaResult.status == 'rejected') {
            logStartUsage('NetCoreDiff', { actionName, diff: 'OWAFailed' });
            return owaResult.reason;
        }
        if (netcoreResult.status == 'rejected') {
            logStartUsage('NetCoreDiff', { actionName, diff: 'NetCoreFailed' });
            return owaResult.value;
        }
        return (
            compareCallback?.(owaResult.value, netcoreResult.value, actionName) ?? owaResult.value
        );
    });
}

import type { StartConfig } from 'owa-shared-start';
import { updateServiceConfig } from 'owa-service/lib/config';
import {
    lazyInitializeMetaOsAppSdkProxy,
    lazyGetOwaAuthTokenFromMetaOsHubProxy,
    lazyGetOwaDecodedAuthTokenFromMetaOsHubProxy,
    lazyInitializeMetaOsCapabilitiesProxy,
    lazyGetContextProxy,
    lazyPreInitOwaAccountSourceListStoreProxy,
    lazyGetAccountServiceConfigProxy,
    lazyIsNAAChannelRecommendedProxy,
} from 'owa-metaos-app-bootstrap/lib/lazyAppBoot';
import { setHostHub } from 'owa-config';
import { addBootCustomData, markFunction } from 'owa-performance';
import { isMsalFlowEnabled } from 'owa-msaljs/lib/isMsalFlowEnabled';

export const getRunBeforeStartForHosted = (config: StartConfig) => {
    const oldRunBeforeStart = config.runBeforeStart;

    return (startConfig: StartConfig) =>
        markFunction(lazyInitializeMetaOsAppSdkProxy.import, 'MetaOSAppBootImportScript')().then(
            async initializeMetaOsAppSdk => {
                await initializeMetaOsAppSdk();

                const getAuthTokenEnabled =
                    !isMsalFlowEnabled() ||
                    (!startConfig.msalConfiguration?.disableGetAuthTokenFallback &&
                        !(await lazyIsNAAChannelRecommendedProxy.importAndExecute()));

                if (getAuthTokenEnabled) {
                    // if using MSAL is disabled, or the host indicates it does not support NAA, override the
                    // getAuthToken callback with the MetaOS Hub SDK getAuthToken callback
                    await lazyGetOwaAuthTokenFromMetaOsHubProxy
                        .import()
                        .then(getAuthToken => updateServiceConfig({ getAuthToken }));
                }

                lazyInitializeMetaOsCapabilitiesProxy.importAndExecute();
                const metaOSContext = await lazyGetContextProxy.importAndExecute();
                const metaOsAppInfo = metaOSContext?.app;
                const metaOsHostInfo = metaOsAppInfo?.host;
                const metaOsHostName = metaOsHostInfo?.name;
                const metaOsHostClientType = metaOsHostInfo?.clientType;
                const userClickTime = metaOsAppInfo?.userClickTime;

                setHostHub(metaOsHostName, metaOsHostClientType);

                if (config.runAfterMetaOsAppInitialized) {
                    config.runAfterMetaOsAppInitialized(
                        metaOsHostName,
                        metaOsHostClientType,
                        metaOSContext?.user?.id,
                        metaOSContext?.user?.tenant?.id
                    );
                }

                updateServiceConfig({
                    metaOSContext: {
                        locale: metaOsAppInfo?.locale || 'en-us',
                        hostName: metaOsHostName,
                        clientType: metaOsHostClientType,
                    },
                });
                if (userClickTime) {
                    addBootCustomData('HubLoadData', { userClickTime });
                }

                // Initialize the account source list store for Calendar and Places metaos app using PUID
                const hostedApp = config.app;
                if (
                    (hostedApp === 'Calendar' ||
                        hostedApp === 'Places' ||
                        hostedApp === 'CalendarDeepLink') &&
                    getAuthTokenEnabled
                ) {
                    const decodedToken =
                        await lazyGetOwaDecodedAuthTokenFromMetaOsHubProxy.importAndExecute();
                    // Cleanup once MetaOS begins to transmit PUID https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/253607
                    if (decodedToken?.puid) {
                        await markFunction(
                            lazyPreInitOwaAccountSourceListStoreProxy.importAndExecute,
                            'MetaOSInitAccountStore'
                        )(
                            decodedToken.puid,
                            metaOSContext?.user?.tenant?.id,
                            metaOSContext?.user?.loginHint,
                            metaOSContext?.user?.userPrincipalName
                        );
                        updateServiceConfig(
                            await lazyGetAccountServiceConfigProxy.importAndExecute()
                        );
                    }
                }

                return oldRunBeforeStart?.(startConfig);
            }
        );
};
